<!--Recepción Facturas -->

<template>
  <div class="fras_Recepcion">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">
        
        <!-- Botón para abrir Finder Documentos -->
        <v-btn 
          v-on='on'
          v-bind="$cfg.btra_cfg.standard"
          v-on:click="btra_Mto({ accion:0 })"          
          title="Recepción Facturas"
          :disabled="!show">
            <v-icon dark>{{ "mdi-archive-arrow-down-outline" }}</v-icon>
        </v-btn>       
      </template>

      <div class="contenedor centrado" v-if="schema">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>
          </div> 
          
          <!-- Botoneras -->
          <div class="conflex pl-2 pt-2">
            <v-btn 
              v-bind="$cfg.btra_cfg.standard"
              :disabled="recepcionada"
              @click="guardar()">
                <v-icon>{{'mdi-content-save'}}</v-icon>
            </v-btn> 
          </div>

          <!-- Controles del Mto -->
          <div class="ctrls conflex contenedor max-width:1240px"> 
            <div class="columna" style="width:350px">
              <v-text-field
                v-bind="$input"
                v-model="accionRow.razon_cta"
                label="Cuenta"
                :disabled=true>          
              </v-text-field>

              <v-text-field
                v-bind="$input"
                v-model="accionRow.cif_cta"
                label="Cif"
                :disabled=true>          
              </v-text-field>

              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.fra_doc.value"
                :label="schema.ctrls.fra_doc.label"
                :disabled="contabilizada">          
              </v-text-field>

              <compfecha :schema="schema.ctrls.fra_fh" :edicion="!contabilizada" />
              
              <compfecha :schema="schema.ctrls.fra_vto" :edicion="!pagada" />            
              
              <div class="conflex">
                <v-select  
                  style="flex: 0 0 40%"
                  v-bind="$select"
                  v-model="schema.ctrls.visado.value"
                  :label="schema.ctrls.visado.label"
                  :items="itemsVisado"
                  item-value="id"
                  item-text="name"
                  :disabled="pagada"                
                  @change="changeVisado">   
                </v-select>
                <compfecha style="flex: 0 0 60%" :schema="schema.ctrls.visado_fh" :edicion=!pagada />
              </div>

              <compfecha :schema="schema.ctrls.fra_fhcon" :edicion="false" />
              <compfecha :schema="schema.ctrls.fra_pago_fh" :edicion="false" />
            </div>

          </div>
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";  
  import { mixinMtoArray } from "@/mixins/mixinMtoArray.js";  
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
    
  export default {
    mixins: [mixinMto, mixinMtoArray],
    components: { base_Header, compfecha },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      show: { type: [String, Boolean], default: "1" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'recepcionFras_M',
        stName:'stMrecepcionFras',

        itemsVisado: [],        
        dialog:false,
      };
    },


    computed: {
      contabilizada() {
        return Number(this.accionRow.fra_conta_asi) > 0;
      },
      pagada() {
        return Number(this.accionRow.fra_pago) != 0;        
      },
      recepcionada() {
        return (this.contabilizada && this.pagada);
        //return Number(this.accionRow.fra_conta_asi)> 0;
      }
    },
   

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Recepción Facturas"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo['F']));
        this.Entorno.btra.footer.extra = [
          { accion:'guardar', btn: "btn_4", disabled:this.recepcionada }
        ];

        // items adicionales
        this.itemsVisado= [
          { id:"0", name:"No" },
          { id:"1", name:"Requerido" },
          { id:"2", name:"Visado" },
        ]
      },


      //
      record_cargado() {        
        if (!this.schema.ctrls.fra_vto.value) {
          var fecha = new Date();
          var dias = Number(this.$store.state.datos_iniciales.config[0].dias_vto_compras);
          fecha.setDate(fecha.getDate() + dias);

          this.schema.ctrls.fra_vto.value= `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, "0")}-${String(fecha.getDate()).padStart(2, "0")}`;          
        }
      },


      // guardo datos factura recepcionada
      async guardar() {   
        // compruebo si se ha introducido un número de factura
        if (!this.schema.ctrls.fra_doc.value) return this.$root.$alert.open('Debe introducir Nº Factura', 'info');
        
        // obtengo datos de los controles en par-valor y le añado el campo id        
        let ctrls= this.ctrl2record_wo_compare(this.schema.ctrls);
        ctrls['id']= this.accionRow.id;

        // llamada API
        let args= { tipo:'fnc', accion: 'facturas', fn_args: { ...ctrls, ...{ accion:"recepcion" }}};
        console.log('args recepcion_fras: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        console.log('apiResult recepcion_fras: ', apiResult);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // mensaje de confirmación
        this.$root.$alert.open('Factura recepcionada correctamente', 'success', 1800);

        // paso datos a schema
        await this.record2ctrl(apiResult.r[0], this.schema.ctrls);

        // recargo datos factura (override guardar MixinMtoArray)       
        mixinMtoArray.methods.guardar.call(this)

        // cierro ventana
        this.dialog= false;
      },


      // entra al cambiar el select de Visado
      // si el campo de fecha visado está vacío, muestro una fecha calculada
      changeVisado(id) {
        if (id!= 1 || this.schema.ctrls.visado_fh.value) return;
        
        var fecha = new Date();
        var dias = Number(this.$store.state.datos_iniciales.config[0].dias_visado);
        fecha.setDate(fecha.getDate() + dias);

        this.schema.ctrls.visado_fh.value= `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, "0")}-${String(fecha.getDate()).padStart(2, "0")}`;   
      }

    }
  };
</script>
